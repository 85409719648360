import apiClient from '../shared-components/utils/ApiClient'

export const FETCH_BIRTHDAYS = 'FETCH_BIRTHDAYS'
export const FETCH_BIRTHDAYS_FIRST = 'FETCH_BIRTHDAYS_FIRST'
export const FETCH_RECENT_BIRTHDAYS = 'FETCH_RECENT_BIRTHDAYS'

export const fetchBirthdays = (page, size) => (dispatch) => {
  dispatch({
    type: FETCH_BIRTHDAYS,
    payload: apiClient.friends.getBirthdays(page, size)
  })
}

export const fetchRecentBirthdays = () => (dispatch) => {
  dispatch({
    type: FETCH_RECENT_BIRTHDAYS,
    payload: apiClient.friends.getRecentBirthdays()
  })
}
