import apiClient from 'shared-components/utils/ApiClient'
import { RSVP_EVENT } from 'actions/event'
import { patchDateOptionsLocal } from 'actions/rsvp'
import { getCurrentEvent } from 'selectors/event'

export const SET_DATE_RESPONSE = 'SET_DATE_RESPONSE'
export const INIT_DATES = 'INIT_DATES'

export const setDateResponse = (id, status) => ({
  type: SET_DATE_RESPONSE,
  payload: { id, status },
})

export const initDates = (dateOptions, currentGuestId) => ({
  type: INIT_DATES,
  payload: { dateOptions, currentGuestId },
})

export const rsvpDateOptions = (
  eventId,
  dto,
  eventCode,
  guestCode,
  location,
  inviterId,
) => ({
  type: RSVP_EVENT,
  payload: apiClient.rsvp.dateOptions(
    eventId,
    dto,
    eventCode,
    guestCode,
    location,
    inviterId,
  ),
})

