import scraperApiClient from 'scraper/ScraperApiClient'

export const FETCH_SCRAPER_STATS = 'FETCH_SCRAPER_STATS'
export const FETCH_SCRAPER = 'FETCH_SCRAPER'
export const SET_CURRENT_SCRAPE_BOT_ID = 'SET_CURRENT_SCRAPE_BOT_ID'

export const fetchScraperStats = (id) => ({
  type: FETCH_SCRAPER_STATS,
  payload: scraperApiClient.scrapers.getStats(id)
})

export const fetchScraper = (id) => ({
  type: FETCH_SCRAPER,
  payload: scraperApiClient.scrapers.getSingle(id)
})

export const setCurrentScrapeBotId = (id) => ({
  type: SET_CURRENT_SCRAPE_BOT_ID,
  payload: id
})
