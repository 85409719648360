import typeToReducer from 'type-to-reducer'
import { FETCH_SCRAPER, FETCH_SCRAPER_STATS, SET_CURRENT_SCRAPE_BOT_ID } from '../actions/scraper'

const defaultStats = {
  total: 0,
  startDate: 0,
  endDate: 0,
  organisationId: 0,
  name: 0,
  description: 0,
  ticketUrl: 0,
  location: 0,
  address: 0,
}

const initialState = {
  currentScrapeBotId: null,
  stats: {
    isFulfilled: false,
    pending: false,
    isRejected: false,
    data: {
      ...defaultStats
    }
  },
  scraper: {
    isFulfilled: false,
    pending: false,
    isRejected: false,
    data: null
  }
}

export default typeToReducer(
  {
    [FETCH_SCRAPER]: {
      PENDING: (state, action) => ({
        ...state,
        scraper: {
          ...state.data,
          isFulfilled: false,
          pending: true,
          isRejected: false,
        }
      }),
      FULFILLED: (state, action) => ({
        ...state,
        scraper: {
          isFulfilled: true,
          pending: false,
          isRejected: false,
          data: action.payload
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        scraper: {
          isFulfilled: false,
          pending: false,
          isRejected: true,
          data: null
        }
      })
    },
    [FETCH_SCRAPER_STATS]: {
      PENDING: (state, action) => ({
        ...state,
        stats: {
          ...state.stats,
          isFulfilled: false,
          pending: true,
          isRejected: false,
        }
      }),
      FULFILLED: (state, action) => ({
        ...state,
        stats: {
          isFulfilled: true,
          pending: false,
          isRejected: false,
          data: action.payload
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        stats: {
          isFulfilled: false,
          pending: false,
          isRejected: true,
          data: {
            ...defaultStats
          }
        }
      })
    },
    [SET_CURRENT_SCRAPE_BOT_ID]: (state, action) => ({
      ...state,
      currentScrapeBotId: action.payload
    })
  },
  initialState
)
