import typeToReducer from 'type-to-reducer'
import { FETCH_ACTIVITY, FETCH_ACTIVITY_FIRST } from '../actions/activity'

const initialState = {
  isFulfilled: false,
  pending: false,
  isRejected: false,
  activity: []
}

export default typeToReducer(
  {
    [FETCH_ACTIVITY]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        pending: true
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          isFulfilled: true,
          pending: false,
          activity: [
            ...state.activity,
            ...action.payload
          ]
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        pending: false,
        isFulfilled: false,
        error: action.payload
      })
    },
    [FETCH_ACTIVITY_FIRST]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        pending: true,
        activity: []
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          isFulfilled: true,
          pending: false,
          activity: action.payload
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        pending: false,
        isFulfilled: false,
        error: action.payload
      })
    }
  },
  initialState
)
