import React from 'react'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import classNames from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 'none',
    padding: theme.spacing(1, 2),
    fontSize: '14px',
  },
  iconSpace: {
    '& > *:first-child': {
      justifyContent: 'space-between',
    },
  },
  fill: {
    width: 24,
  },
  fillEnd: {
    width: 24,
    marginRight: '-4px',
  },
  progress: {
    marginLeft: theme.spacing(1),
    color: 'white',
  },
  primaryInactive: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
    },
  },
  secondaryColor: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: '#9465D6',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
  tertiaryColor: {
    color: '#14221B',
    backgroundColor: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary[100],
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.tertiary.main,
      },
    },
  },
  tertiaryInactive: {
    backgroundColor: theme.palette.tertiary[100],
    color: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary[50],
    },
  },
  greyColor: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.gray.main,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.gray.main,
      },
    },
  },
  greyInactive: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark,
    },
  },
  whiteColor: {
    color: theme.palette.text.primary,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.gray.light,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.gray.light,
      },
    },
  },
  whiteInactive: {
    backgroundColor: theme.palette.gray.light,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.gray.dark,
    },
  },
  redColor: {
    color: '#fff',
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.red.light,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.red.light,
      },
    },
  },
  redInactive: {
    backgroundColor: theme.palette.red.main,
    color: theme.palette.text.disabled,
    '&:hover': {
      backgroundColor: theme.palette.red.dark,
    },
  },
  primaryLightColor: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
    },
    border: `1px solid ${theme.palette.primary.lightDark}`,
  },
  primaryLightInactive: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter,
    },
  },
}))

const customColors = ['tertiary', 'grey', 'white', 'red', 'primaryLight']

/**
 * Use BashButton instead
 * @deprecated
 */
const FlatButton = (
  {
    color = 'primary',
    variant = 'contained',
    children,
    disabled,
    loading,
    disableElevation = true,
    className,
    inactive,
    startIcon,
    endIcon,
    fullWidth,
    elevation,
    size = 'large',
    ...otherProps
  },
  ref,
) => {
  const classes = useStyles()
  return (
    <Button
      ref={ref}
      disabled={disabled || loading}
      disableElevation={disableElevation}
      variant={variant}
      color={customColors.includes(color) ? 'inherit' : color}
      startIcon={
        fullWidth && endIcon && !startIcon ? (
          <span className={classes.fill} />
        ) : (
          startIcon
        )
      }
      endIcon={
        fullWidth && startIcon && !endIcon ? (
          <span className={classes.fillEnd} />
        ) : (
          endIcon
        )
      }
      fullWidth={fullWidth}
      size={size}
      className={classNames(
        className,
        classes.root,
        inactive ? classes[`${color}Inactive`] : classes[`${color}Color`],
        fullWidth && (startIcon || endIcon) && classes.iconSpace,
      )}
      {...otherProps}
    >
      {children}
      {loading && (
        <CircularProgress
          size={20}
          color='inherit'
          className={classes.progress}
        />
      )}
    </Button>
  )
}

export default React.forwardRef(FlatButton)
