import { combineReducers } from 'redux'
import event from './event'
import notifications from 'shared-components/redux/notifications/reducer'
import pinning from './pinning'
import modals from './modals'
import rsvp from './rsvp'
import organisation from './organisation'
import user from './user'
import featured from './featured'
import activity from './activity'
import friends from './friends'
import scraper from './scraper'

const rootReducer = () => combineReducers({
  event,
  notifications,
  pinning,
  modals,
  rsvp,
  organisation,
  user,
  featured,
  activity,
  friends,
  scraper
})

export default rootReducer
