import { SET_SNACKBAR } from './types'

export const setSnackbar = (variant, message) => ({
  type: SET_SNACKBAR,
  payload: { open: true, variant, message }
})

export const closeSnackbar = () => ({
  type: SET_SNACKBAR,
  payload: { open: false }
})
