import apiClient from 'shared-components/utils/ApiClient'

export const FETCH_ORGANISATION = 'FETCH_ORGANISATION'
export const FETCH_EVENTS = 'FETCH_EVENTS'
export const FETCH_PAST_EVENTS = 'FETCH_PAST_EVENTS'
export const SET_SHOW_PAST = 'SET_SHOW_PAST'
export const FETCH_MEMBERS = 'FETCH_MEMBERS'
export const ADD_MEMBERS = 'ADD_MEMBERS'
export const CHANGE_MEMBER = 'CHANGE_MEMBER'
export const DELETE_MEMBER = 'DELETE_MEMBER'
export const LEAVE_ORGANISATION = 'LEAVE_ORGANISATION'
export const FETCH_CONNECTED_APPS = 'FETCH_CONNECTED_APPS'
export const AUTHORISE_MOLLIE = 'AUTHORISE_MOLLIE'
export const FETCH_ORG_EVENTS = 'FETCH_ORG_EVENTS'
export const FETCH_ORG_PAST_EVENTS = 'FETCH_ORG_PAST_EVENTS'
export const RESET_ORG_EVENTS = 'RESET_ORG_EVENTS'
export const FETCH_BILLING_INFO = 'FETCH_BILLING_INFO'
export const SET_BILLING_INFO_FIELD = 'SET_BILLING_INFO_FIELD'

const orgImgOptions = {
  orgAvatarSize: 'sm',
  avatarSize: 'sm',
  eventImageSize: 'card-xs',
}

export const fetchOrganisation = (username) => ({
  type: FETCH_ORGANISATION,
  payload: apiClient.organisation.find(username),
})

export const fetchOrganisationId = (id) => ({
  type: FETCH_ORGANISATION,
  payload: apiClient.organisation.get(id),
})

export const fetchBillingInfo = (id) => ({
  type: FETCH_BILLING_INFO,
  payload: async () => {
    const response = await apiClient.organisation.getBillingDetails(id)
    if (response == null) throw new Error('No billing details found')
    return response
  },
})

export const fetchBillingInfoFulfilled = (payload) => ({
  type: `${FETCH_BILLING_INFO}_FULFILLED`,
  payload,
})

export const setBillingInfoField = (field, value) => ({
  type: SET_BILLING_INFO_FIELD,
  payload: { field, value },
})

export const fetchOrganisationWithId = (id) => ({
  type: FETCH_ORGANISATION,
  payload: apiClient.organisationAdmin.getMe(id),
})

export const patchLocalOrganisation = (organisation) => ({
  type: `${FETCH_ORGANISATION}_FULFILLED`,
  payload: organisation,
})

export const fetchEvents = (id) => ({
  type: FETCH_EVENTS,
  payload: apiClient.organisation.getEvents(id, 0, 10),
})

export const fetchOrgEvents = (page, size) => async (dispatch, getState) => {
  const orgId = getState().organisation.organisation.id
  dispatch({
    type: FETCH_ORG_EVENTS,
    payload: apiClient.organisation.getEvents(orgId, page, size),
  })
}

export const fetchOrgPastEvents =
  (page, size) => async (dispatch, getState) => {
    const orgId = getState().organisation.organisation.id
    dispatch({
      type: FETCH_ORG_PAST_EVENTS,
      payload: apiClient.organisation.getPastEvents(orgId, page, size),
    })
  }

export const resetOrgEvents = () => ({
  type: RESET_ORG_EVENTS,
})

export const patchLocalOrganistionEvents = (events) => ({
  type: `${FETCH_EVENTS}_FULFILLED`,
  payload: events,
})

export const fetchMembers = (id) => ({
  type: FETCH_MEMBERS,
  payload: apiClient.organisationAdmin.getUsers(id),
})

export const inviteMembers = (orgId, members) => ({
  type: FETCH_MEMBERS,
  payload: apiClient.organisationAdmin.inviteUsers(orgId, members),
})

export const changeMemberRole = (orgId, orgUserId, role) => ({
  type: CHANGE_MEMBER,
  payload: apiClient.organisationAdmin.changeRole(orgId, orgUserId, role),
})

export const deleteMember = (orgId, orgUserId) => async (dispatch) => {
  await apiClient.organisationAdmin.deleteUser(orgId, orgUserId)
  dispatch({
    type: DELETE_MEMBER,
    payload: orgUserId,
  })
}

export const leaveOrganisation = (orgUserId) => ({
  type: LEAVE_ORGANISATION,
  payload: apiClient.organisationAdmin.rejectInvite(orgUserId),
})

export const fetchPastEventsFirstPage = (id) => ({
  type: FETCH_PAST_EVENTS,
  payload: apiClient.organisation.getPastEvents(id, 0, 10),
})

export const fetchConnectedApps = (orgId) => ({
  type: FETCH_CONNECTED_APPS,
  payload: apiClient.organisationAdmin.getConnectedApps(orgId),
})

export const authoriseMollie = (code, orgId) => ({
  type: AUTHORISE_MOLLIE,
  payload: apiClient.organisationAdmin.authoriseMollie(code, orgId),
})

export const fetchOrganisationAndEvents =
  (username) => (dispatch, getState) => {
    dispatch(fetchOrganisation(username)).then(() => {
      dispatch(fetchEvents(getState().organisation.organisation.id))
    })
  }

export const fetchOrganisationIdAndEvents = (id) => (dispatch, getState) => {
  dispatch(fetchOrganisationId(id)).then(() => {
    dispatch(fetchEvents(getState().organisation.organisation.id))
  })
}

export const fetchOrganisationAndEventsWithId = (id) => (dispatch) => {
  dispatch(fetchEvents(id))
}

export const setShowPast = (showPast) => ({
  type: SET_SHOW_PAST,
  payload: showPast,
})
