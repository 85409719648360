// class HttpError extends Error {
//   constructor (status, ...params) {
//     // Pass remaining arguments (including vendor specific ones) to parent constructor
//     super(...params)
//
//     // Maintains proper stack trace for where our error was thrown (only available on V8)
//     if (Error.captureStackTrace) {
//       Error.captureStackTrace(this, HttpError)
//     }
//
//     this.name = 'HttpError'
//     // Custom debugging information
//     this.status = status
//   }
// }

import { addBreadcrumb } from '@sentry/nextjs'
import mixpanel from 'mixpanel-browser'

// import { ampli } from '../../ampli'

class HttpError extends Error {
  constructor(response, body) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(response.status)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError)
    }

    this.name = 'HttpError'

    addBreadcrumb({
      category: 'data',
      message: response.message,
      data: {
        status: response.status,
        message: response.message,
        error: response.error,
        path: response.path,
        body: JSON.stringify(body),
      },
      type: 'error',
      level: 'error',
    })

    // captureException('HttpError')
  }
}

export default class RestClient {
  constructor(baseUrl = '', { headers = {} } = {}, isMobileOrTablet = false) {
    this.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Client-Mixpanel-Distinct-Id': mixpanel.get_distinct_id(),
      'X-Client-Web-Device-Type': isMobileOrTablet ? 'mobile' : 'desktop',
      'X-Client-Version-Code': 1,
      ...headers,
    }
    this.baseUrl = baseUrl
    this.imgOptions = null
  }

  setImgOptions(imgOptions) {
    this.imgOptions = imgOptions
    return this
  }

  _removeBlankAttr(obj) {
    Object.keys(obj).forEach((key) => obj[key] == null && delete obj[key])
  }

  async _parseIfJson(response) {
    var contentType = response.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json()
    } else if (+response.headers.get('content-length') === 0) {
      return
    }
    throw new HttpError('NO_JSON')
  }

  async _handleResponse(response) {
    const body = await this._parseIfJson(response)
    if (response.ok) {
      return body
    } else {
      const filteredStatuses = [403, 404]
      if (!filteredStatuses.includes(response.status)) {
        if (body) {
          return Promise.reject(body)
        }
        throw new HttpError(response, body)
        // Sentry.captureException(new HttpError(), {
        //   tags: {
        //     status: response.status,
        //     message: response.message,
        //     error: response.error,
        //     path: response.path,
        //     body: JSON.stringify(body)
        //   }
        // })
      } else {
        if (process.env.NODE_ENV !== 'development') {
          // throw new Error('403')
        }
      }
    }
  }

  _fullRoute(url) {
    return `${this.baseUrl}${url}`
  }

  async _fetch(route, method, body, queryObj, noJson) {
    // const deviceId = ampli.isLoaded ? ampli.client.getDeviceId() : null

    const imgOptions = this.imgOptions
    this.imgOptions = null
    if (!route) throw new Error('Route is undefined')

    let fullRoute = this._fullRoute(route)
    if (queryObj || imgOptions) {
      const qs = require('qs')
      const query = qs.stringify(
        { ...imgOptions, ...queryObj },
        { skipNulls: true },
      )
      fullRoute = `${fullRoute}?${query}`
    }

    const opts = {
      method,
      mode: 'cors',
      credentials: 'include',
      headers: {
        ...this.headers,
        // 'X-Client-Amplitude-Device-Id': deviceId,
      },
    }
    // if (body) {
    //   this._removeBlankAttr(body)
    // }
    // eslint-disable-next-line no-undef
    try {
      const promise = await fetch(
        fullRoute,
        body ? { ...opts, body: noJson ? body : JSON.stringify(body) } : opts,
      )
      return this._handleResponse(promise)
    } catch (error) {
      // Handle the fetch error gracefully
      if (fullRoute.endsWith('/users')) {
        // This will happen a lot and is expected
        return null
      } else {
        console.error('Fetch error:', error)
        throw new HttpError('FETCH_ERROR', error) // Throw for other routes
      }
    }
  }

  GET(route, query) {
    return this._fetch(route, 'GET', null, query)
  }
  POST(route, body, query, noJson) {
    return this._fetch(route, 'POST', body, query, noJson)
  }
  PUT(route, body, query) {
    return this._fetch(route, 'PUT', body, query)
  }
  PATCH(route, body, query) {
    return this._fetch(route, 'PATCH', body, query)
  }
  DELETE(route, query) {
    return this._fetch(route, 'DELETE', null, query)
  }
}
