import React, { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

const WebSessionTracking = () => {
  useEffect(() => {
    let sessionStartTime = Date.now()
    let sessionLength = 0
    let isSessionActive = true
    let idleTimer

    // Reset idle timer and handle user activity
    const resetIdleTimer = () => {
      clearTimeout(idleTimer)
      idleTimer = setTimeout(() => {
        sessionLength += (Date.now() - sessionStartTime) / 1000
        isSessionActive = false
        trackSessionEnd()
      }, 60000) // 60 seconds of inactivity
    }

    const handleUserActivity = () => {
      if (!isSessionActive) {
        sessionStartTime = Date.now()
        isSessionActive = true
      }
      resetIdleTimer()
    }

    // Handle visibility changes (tab switch or minimize)
    const handleVisibilityChange = () => {
      if (document.hidden) {
        sessionLength += (Date.now() - sessionStartTime) / 1000
        isSessionActive = false
        clearTimeout(idleTimer)
      } else {
        sessionStartTime = Date.now()
        isSessionActive = true
        resetIdleTimer()
      }
    }

    // Track session end and update Mixpanel
    const trackSessionEnd = () => {
      if (isSessionActive) {
        sessionLength += (Date.now() - sessionStartTime) / 1000
      }

      let totalSessions =
        parseInt(localStorage.getItem('total_web_sessions'), 10) || 0
      let totalSessionLength =
        parseFloat(localStorage.getItem('total_web_session_length')) || 0

      totalSessions += 1
      totalSessionLength += sessionLength

      localStorage.setItem('total_web_sessions', totalSessions)
      localStorage.setItem('total_web_session_length', totalSessionLength)
      mixpanel.track('Web Session', {
        'Session Length': sessionLength,
        'Total Web Sessions': totalSessions,
        'Total Web Session Length': totalSessionLength,
      })
    }

    // Add event listeners
    document.addEventListener('visibilitychange', handleVisibilityChange)
    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)
    window.addEventListener('beforeunload', trackSessionEnd)

    // Initialize idle timer
    resetIdleTimer()

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
      window.removeEventListener('beforeunload', trackSessionEnd)
      clearTimeout(idleTimer)
    }
  }, [])

  return null // This component does not render anything
}

export default WebSessionTracking
