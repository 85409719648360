import apiClient from '../shared-components/utils/ApiClient'

export const FETCH_ACTIVITY = 'FETCH_ACTIVITY'
export const FETCH_ACTIVITY_FIRST = 'FETCH_ACTIVITY_FIRST'

export const fetchActivity = (page, size) => (dispatch) => {
  dispatch({
    type: FETCH_ACTIVITY,
    payload: apiClient.user.getNotifications(page, size)
  })
}

export const fetchActivityFirstPage = (size) => (dispatch) => {
  dispatch({
    type: FETCH_ACTIVITY_FIRST,
    payload: apiClient.user.getNotifications(0, size)
  })
}
