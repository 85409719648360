import typeToReducer from 'type-to-reducer'
import { FETCH_BIRTHDAYS, FETCH_BIRTHDAYS_FIRST, FETCH_RECENT_BIRTHDAYS } from '../actions/friends'

const initialState = {
  isFulfilled: false,
  pending: false,
  isRejected: false,
  birthdays: [],
  recentBirthdays: {
    isFulfilled: false,
    pending: false,
    isRejected: false,
    value: []
  }
}

export default typeToReducer(
  {
    [FETCH_BIRTHDAYS]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        pending: true
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          isFulfilled: true,
          pending: false,
          birthdays: [
            ...state.birthdays,
            ...action.payload
          ]
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        pending: false,
        isFulfilled: false,
        error: action.payload
      })
    },
    [FETCH_BIRTHDAYS_FIRST]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        pending: true,
        activity: []
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          isFulfilled: true,
          pending: false,
          birthdays: action.payload
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        pending: false,
        isFulfilled: false,
        error: action.payload
      })
    },
    [FETCH_RECENT_BIRTHDAYS]: {
      PENDING: (state, action) => ({
        ...state,
        recentBirthdays: {
          ...state.recentBirthdays,
          isFulfilled: false,
          pending: true
        }
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          recentBirthdays: {
            isFulfilled: true,
            pending: false,
            isRejected: false,
            value: action.payload
          }
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        recentBirthdays: {
          isRejected: true,
          pending: false,
          isFulfilled: false,
          error: action.payload
        }
      })
    }
  },
  initialState
)
