import { createSelector } from 'reselect'
import { useCallback } from 'react'
import mixpanel from 'mixpanel-browser'
import { useSelector } from 'react-redux'
import { getCurrentEvent, getUserState } from '../selectors/event'

export const eventProperties = createSelector(
  event => event,
  (event) => {
    let properties = {
      'Bash ID': event.id,
      'Bash Event Type': event.type,
      'Bash Number of Guests': event.statusCounts ? Object.values(event.statusCounts).reduce((a, b) => a + b) : 0,
      'BASH TBA': !!event.toBeAnnounced,
      'Guests Can See Guests': event.guestsCanSeeGuests,
      'Guests Can Invite Guests': event.guestsCanInvite,
      'Bash Privacy Type': event.privacyType,
      'Started as Idea': event.type === 'IDEA' || event.wasIdeaEvent,
      'Bash Count Going': event.statusCounts?.going,
      'Bash Count Maybe': event.statusCounts?.maybe,
      'Bash Count Cant': event.statusCounts?.cant,
      'Bash Count Requests': event.statusCounts?.requests,
      'Bash Count Denied Requests': event.statusCounts?.deniedRequests,
      'Bash Count Invited': event.statusCounts?.invited,
      'Bash Count Gave Availability': event.statusCounts?.gaveAvailability,
      'Bash Count Likes': event.statusCounts?.likes,
      'Bash Count In Chat': event.statusCounts?.inChat,
      'Bash Count Not In Chat': event.statusCounts?.notInChat,
      'Bash Expected Number Of Guests': event.expectedNumberOfGuests
    }
    if (event.organisation) {
      properties = {
        ...properties,
        'Bash Organisation ID': event.organisation.id,
        'Bash Organisation Private Profile': event.organisation.privateProfile
      }
    } else {
      properties = {
        ...properties,
        'Bash Organiser ID': event.hosts ? event.hosts[0]?.model?.user?.id : event.organisers[0].id
      }
    }
    return properties
  }
)

export const mixpanelTrackEventState = (name, event, extraProperties, state) => {
  const properties = eventProperties(event)
  const userState = getUserState(state)
  mixpanel.track(name, { ...properties, 'User State': userState, ...extraProperties })
}

export default function useMixpanelTrackEvent (event) {
  const currentEvent = useSelector(getCurrentEvent)
  const eventToUse = event || currentEvent
  const properties = eventToUse ? eventProperties(eventToUse) : {}
  const userState = useSelector(getUserState)

  const trackEvent = useCallback((name, extraProperties = {}) => {
    mixpanel.track(name, { ...properties, 'User State': userState, ...extraProperties })
  }, [properties, userState])

  return trackEvent
}
