import { defLang } from 'config/i18n'

export const initialEvent = {
  organisers: [{ name: '' }],
  startDate: '',
  endDate: '',
  imageUrl: '',
  guests: [],
  location: '',
  name: '',
  messages: [],
  statusCounts: {
    going: 0,
    maybe: 0,
    cant: 0,
    invited: 0,
  },
}

export const initialEventDraft = {
  privacyType: 'PRIVATE',
  name: '',
  startDate: null,
  endDate: null,
  location: '',
  callLink: '',
  description: '',
  covidMeasures: '',
  responseType: 'DEFAULT',
  limitNumberOfGuests: false,
  expectedNumberOfGuests: 0,
  extraAttendees: 0,
  guestsCanSeeGuests: true,
  guestsCanInvite: true,
  visibleOnProfile: true,
  ticketOptions: [],
  type: 'INVITE',
  ticketLink: null,
  ticketPrice: null,
  ticketCurrency: 'EUR',
  dateToBeAnnounced: false,
  tags: [],
  organisers: [{ name: '' }],
  hosts: null,
  media: [],
  guests: [
    {
      status: 'GOING',
      name: 'Adam',
      avatarUrl: '/images/dummyprofiles/adam.png',
      gaveDateOptions: true,
      user: {
        name: 'Adam',
        avatarUrl: '/images/dummyprofiles/adam.png',
      },
    },
    {
      status: 'GOING',
      name: 'Maggie',
      avatarUrl: '/images/dummyprofiles/maggie.png',
      gaveDateOptions: true,
      user: {
        name: 'Maggie',
        avatarUrl: '/images/dummyprofiles/maggie.png',
      },
    },
    {
      status: 'GOING',
      name: 'Christie',
      avatarUrl: '/images/dummyprofiles/christie.png',
      gaveDateOptions: true,
      user: {
        name: 'Christie',
        avatarUrl: '/images/dummyprofiles/christie.png',
      },
    },
    {
      status: 'MAYBE',
      name: 'Eric',
      avatarUrl: '/images/dummyprofiles/eric.png',
      gaveDateOptions: true,
      user: {
        name: 'Eric',
        avatarUrl: '/images/dummyprofiles/eric.png',
      },
    },
    {
      status: 'CANT',
      name: 'Anna',
      avatarUrl: '/images/dummyprofiles/anna.png',
      gaveDateOptions: true,
      user: {
        name: 'Anna',
        avatarUrl: '/images/dummyprofiles/anna.png',
      },
    },
  ],
  statusCounts: {
    going: 3,
    maybe: 1,
    cant: 1,
    invited: 0,
  },
  imageUrl: '',
  imageUrls: null,
  joinOptions: [
    {
      id: 'first',
      idInfo: {
        id: 'first',
        source: 'INTERNAL',
      },
      name: defLang === 'en' ? 'Standard' : 'Standaard',
      price: 0,
      chooseYourOwnPrice: false,
      questions: [],
      isNew: true,
      maxAmountPerGuest: 1,
      type: 'TICKET',
    },
  ],
}
