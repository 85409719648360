import { createWrapper } from 'next-redux-wrapper'
import createRootReducer from '../src/reducers'
import { createStore, applyMiddleware } from 'redux'
import promiseMiddleware from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import sideEffectMiddleware from 'utils/sideEffectMiddleware'

export const makeStore = context => createStore(
  createRootReducer(),
  {},
  composeWithDevTools(
    applyMiddleware(
      promiseMiddleware,
      thunk,
      sideEffectMiddleware
    )
  )
)

export const wrapper = createWrapper(makeStore, { debug: false })
