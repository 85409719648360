import mixpanel from 'shared-components/utils/mixpanel'
// import { ampli } from '../ampli'

const sideEffectMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case 'FETCH_USER_FULFILLED': {
      if (action?.payload?.id) {
        mixpanel.identify(action.payload.id)
      }
    }
    case 'RSVP_EVENT_FULFILLED': {
      if (action.payload) {
        mixpanel.identify(mixpanel.get_distinct_id())
        mixpanel.addGuestToList(action.payload.id)
        if (action.payload.myGuest && !action.payload.myGuest.user) {
          if (typeof window !== 'undefined') {
            window.localStorage &&
              window.localStorage.setItem(
                `gc_${action.payload.code}`,
                action.payload.myGuest.code,
              )
            window.localStorage &&
              window.localStorage.setItem(
                `gc_${action.payload.id}`,
                action.payload.myGuest.code,
              )
          }
        }
      }
      break
    }
    case 'FETCH_PAYMENT_DETAILS_FULFILLED':
      if (action.payload.event) {
        store.dispatch({
          type: 'RSVP_EVENT_FULFILLED',
          payload: action.payload.event,
        })
      }
      break
  }

  return next(action)
}

export default sideEffectMiddleware
