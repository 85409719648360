import typeToReducer from 'type-to-reducer'
import { INIT_DATES, SET_DATE_RESPONSE } from 'actions/pinning'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
  dateResponses: {}
}

const createInitDates = (dateOptions, guestId) => {
  const initDates = {}
  if (dateOptions) {
    for (const dateOption of dateOptions) {
      let status = 'GOING'
      if (guestId && dateOption.guestStatuses) {
        const guestStatus = dateOption.guestStatuses.find(gs => gs.guest.id === guestId)
        if (guestStatus && guestStatus.status) status = guestStatus.status
      }
      initDates[dateOption.id] = status
    }
  }
  return initDates
}

export default typeToReducer(
  {
    [HYDRATE]: (state, action) => ({
      ...state, // use previous state
      ...action.payload.pinning // apply delta from hydration
    }),
    [SET_DATE_RESPONSE]: (state, action) => {
      return {
        ...state,
        dateResponses: {
          ...state.dateResponses,
          [action.payload.id]: action.payload.status
        }
      }
    },
    [INIT_DATES]: (state, action) => {
      return {
        ...state,
        dateResponses: createInitDates(action.payload.dateOptions, action.payload.currentGuestId)
      }
    }
  },
  initialState
)
