import typeToReducer from 'type-to-reducer'
import { SET_SNACKBAR } from './types'

const initialState = {
  snackbar: {
    open: false,
    variant: 'info',
    message: 'Hello world'
  }
}

export default typeToReducer(
  {
    [SET_SNACKBAR]: (state, action) => ({
      ...state,
      snackbar: {
        ...state.snackbar,
        ...action.payload
      }
    })
  },
  initialState
)
