import typeToReducer from 'type-to-reducer'
import { FETCH_FEATURED, FETCH_FEATURED_PAGES } from 'actions/featured'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
  loading: false,
  isFulfilled: false,
  isRejected: false,
  error: null,
  items: [],
  featuredPages: []
}

export default typeToReducer(
  {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.featured,
      items: action.payload.featured.items?.length ? action.payload.featured.items : state.items
    }),
    [FETCH_FEATURED]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        loading: true
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          isFulfilled: true,
          items: action.payload
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        error: action.payload
      })
    },
    [FETCH_FEATURED_PAGES]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        loading: true
      }),
      FULFILLED: (state, action) => {
        return {
          ...state,
          isFulfilled: true,
          featuredPages: action.payload
        }
      },
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        error: action.payload
      })
    },
  },
  initialState
)
