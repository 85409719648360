import React, { useEffect } from 'react'

import { wrapper } from '@redux/store'
import theme from 'shared-components/theme'

import 'config/i18n'
import '../public/index.css'
import StylesProvider from '@material-ui/styles/StylesProvider'
import CssBaseline from '@material-ui/core/CssBaseline'
import createGenerateClassName from '@material-ui/styles/createGenerateClassName'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'
import NextNProgress from 'nextjs-progressbar'
import { useRouter } from 'next/router'
import apiClient from '../src/shared-components/utils/ApiClient'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser, fetchUserBackground } from 'actions/user'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import mixpanel from 'mixpanel-browser'
import { getIsEventsAdmin } from 'selectors/event'
import ScraperProgressView from 'components/Scraper/ScraperProgressView'
import CookieModal from '../src/components/Cookies/CookieModal.js'
import WebSessionTracking from 'shared-components/utils/MixpanelWebSession'
// import { ampli } from '../src/ampli'

const AppWrapper = dynamic(() => import('../src/components/AppWrapper'))

const generateClassName = createGenerateClassName({
  productionPrefix: 'c_app',
  seed: 'c_app',
  // productionPrefix: '',
  // seed: '',
})

function MyApp({ Component, pageProps, err }) {
  const { t, ready } = useTranslation('common')
  const dispatch = useDispatch()
  const router = useRouter()
  const query = router.query
  const utmSource = query.utm_source
  const utmMedium = query.utm_medium
  const utmCampaign = query.utm_campaign
  const featured = useSelector((state) => state.featured)
  const isEventsAdmin = useSelector(getIsEventsAdmin)

  useEffect(() => {
    if (!!utmSource || !!utmMedium || !!utmCampaign) {
      mixpanel.track('Open UTM Link', {
        'UTM Source': utmSource,
        'UTM Medium': utmMedium,
        'UTM Campaign': utmCampaign,
      })
      apiClient.general.linkOpened(utmSource, utmMedium, utmCampaign)
    }
  }, [utmSource, utmMedium, utmCampaign])

  useEffect(() => {
    dispatch(fetchUser())
  }, [])

  useEffect(() => {
    dispatch(fetchUserBackground())
  }, [router.asPath])

  // useEffect(() => {
  //   if (!featured.loading && featured.featuredPages.length === 0) {
  //     dispatch(fetchFeaturedPages())
  //   }
  // }, [featured])

  // useEffect(() => {
  //   ampli.load({
  //     client: {
  //       apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
  //       configuration: {
  //         minIdLength: 1 // Set minimum ID length to 1 instead of the default of 5 to allow user IDs below 10000 to be tracked
  //       }
  //     }
  //   })
  // }, [])

  if (!ready) {
    return null
  }

  if (Component.trimApp) {
    return (
      <>
        <Head>
          <title>{t('websiteTitle')}</title>
          <meta
            name='description'
            content='Where Hype Starts'
            key='description'
          />
          <meta
            name='viewport'
            content='minimum-scale=1, initial-scale=1, width=device-width'
          />
          <meta key='ogTitle' property='og:title' content='BASH' />
          <meta
            key='ogDescription'
            property='og:description'
            content='Where Hype Starts'
          />
          <meta
            key='ogImage'
            property='og:image'
            itemProp='image'
            content='/android-chrome-192x192.png'
          />
        </Head>

        <StylesProvider generateClassName={generateClassName}>
          {/*<StylesProvider>*/}
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Component {...pageProps} err={err} />
          </MuiThemeProvider>
        </StylesProvider>
      </>
    )
  }

  return (
    <>
      <AppWrapper>
        <NextNProgress
          color='#A373F1'
          startPosition={0.6}
          stopDelayMs={100}
          height={2}
          options={{
            showSpinner: false,
          }}
          showOnShallow={false}
        />
        {!router.pathname.endsWith('/widget') && <CookieModal></CookieModal>}
        <WebSessionTracking />
        <Component {...pageProps} err={err} />
        {/* {!isDesktop && isLoggedIn && <NavBar />} */}
        {isEventsAdmin && router.route.includes('admin/pages') && (
          <ScraperProgressView />
        )}
      </AppWrapper>
    </>
  )
}

export default wrapper.withRedux(MyApp)
