import RestClient from '../shared-components/utils/RestClient'
import { ScrapeTaskChart } from './scraper.dto'

const EventDraftState = ['NEW', 'PUBLISHED', 'REMOVED']

export class ScraperApiClient extends RestClient {
  constructor(baseUrl, options = {}) {
    super(baseUrl, options)
  }

  eventDrafts = {
    get: (page, size, state, scrapeBotId, step) =>
      this.GET('/event-drafts', { page, size, state, scrapeBotId, step }),
    update: (draftId, draft) => this.PATCH(`/event-drafts/${draftId}`, draft),
    publish: (draft, eventToSave, useDefaultImage) =>
      this.POST(`/event-drafts/${draft.id}/publish`, eventToSave, {
        useDefaultImage,
      }),
    autoPublish: (eventDrafts) =>
      this.POST('/scheduler/event-drafts/publish', { eventDrafts }),
  }

  events = {
    merge: (body) => this.POST('/events/merge', body),
  }

  organisationDrafts = {
    patch: (draftId, draft) =>
      this.PATCH(`/organisation-drafts/${draftId}`, draft),
  }

  reviews = {
    getNextEvent: () => this.GET('/reviews/events/next'),
    reviewEvent: (body) => this.POST('/reviews/events/review', body),
  }

  scrapers = {
    get: (page, size, query, regionId, scraperStates, meOnly) =>
      this.GET('/scrape-bots', {
        page,
        size,
        query,
        regionId,
        scraperStates,
        meOnly,
      }),
    getFilters: () => this.GET('/scrape-bots/filters', {}),
    getSimilar: (source) => this.GET('/scrape-bots/similar', { source }),
    comments: {
      create: (scrapeBotId, createCommentDto) =>
        this.POST(`/scrape-bots/${scrapeBotId}/comments`, createCommentDto),
      patch: (commentId, createCommentDto) =>
        this.PATCH(`/scrape-bots/comments/${commentId}`, createCommentDto),
      delete: (commentId) => this.DELETE(`/scrape-bots/comments/${commentId}`),
      get: (scrapeBotId, key, size) =>
        this.GET(`/scrape-bots/${scrapeBotId}/comments`, { key, size }),
    },
    getSingle: (id) => this.GET(`/scrape-bots/${id}`),
    getStats: (id) => this.GET(`/scrape-bots/${id}/stats`),
    runScraper: (id) => this.POST(`/scrape-bots/${id}/run`),
    runScraperNow: (id) =>
      this.POST(`/scraper-queue/schedule/scrape-bots/${id}`),
    deleteScraper: (id) => this.DELETE(`/scrape-bots/${id}`),
    update: (scraperId, scraper) =>
      this.PATCH(`/scrape-bots/${scraperId}`, scraper),
    createEvent: (scraperId, event, useDefaultImage) =>
      this.POST(`/scrape-bots/${scraperId}/events/publish`, event, {
        useDefaultImage,
      }),
    getUploadUrl: (format, scraperId) =>
      this.GET(`/scrape-bots/${scraperId}/imageUploadUrl`, { format }),
    importAll: (scraperId, importAll = false) =>
      this.POST(`/scrape-bots/${scraperId}/import?processAll=${importAll}`),
    processTask: (scrapeBotId) =>
      this.POST(`/scheduler/process-task?scrapeBotId=${scrapeBotId}`),
    resetEvents: (scrapeBotId) =>
      this.POST(`/scrape-bots/${scrapeBotId}/reset`),
    create: (scraper) => this.POST('/scrape-bots', scraper),
    approveBot: (id, preferGPTImage) =>
      this.POST(`/scrape-bots/${id}/approve`, {
        preferGPTImage,
      }),
    rerunBot: (id, prompt) =>
      this.POST(`/scrape-bots/${id}/rerun?prompt=${prompt}`),
    rejectBot: (id, comment) =>
      this.POST(`/scrape-bots/${id}/reject?comment=${comment}`),
    getActivity: (id, page, size) =>
      this.GET(`/scrape-bots/${id}/activity`, { page, size }),
    getRunningScrapers: () => this.GET('/scrape-bots/running'),
    getScrapeTasks: (id, page, size) =>
      this.GET(`/scrape-bots/${id}/tasks`, { page, size }),
    importJson: (scraperId, formData) =>
      fetch(`${this.baseUrl}/scrape-bots/${scraperId}/import/json`, {
        method: 'POST',
        body: formData,
        mode: 'cors',
        credentials: 'include',
      }),
  }

  stats = {
    getRegionScrapeBotStats: () => this.GET('/stats/regions/scrape-bots'),
    getScrapeTaskStats: () => this.GET('/stats/scrape-tasks'),
  }

  regions = {
    get: () => this.GET('/scrape-bots/regions'),
  }

  scrapeTasks = {
    getChart: (id: number): Promise<ScrapeTaskChart | null> =>
      this.GET(`/scrape-tasks/${id}/chart`),
    getQueuedByChart: (id: number): Promise<ScrapeTaskChart[]> =>
      this.GET(`/scrape-tasks/${id}/queued-by/chart`),
  }
}

const client = new ScraperApiClient(process.env.NEXT_PUBLIC_SCRAPER_API_HOST)

export default client
