import typeToReducer from 'type-to-reducer'
import {
  AUTHORISE_MOLLIE,
  CHANGE_MEMBER,
  DELETE_MEMBER,
  FETCH_BILLING_INFO,
  FETCH_CONNECTED_APPS,
  FETCH_EVENTS,
  FETCH_ORGANISATION,
  FETCH_PAST_EVENTS,
  LEAVE_ORGANISATION,
  RESET_ORG_EVENTS,
  SET_BILLING_INFO_FIELD,
  SET_SHOW_PAST,
} from 'actions/organisation'
import { FETCH_MEMBERS } from '../actions/organisation'
import { filter, map } from 'underscore'
import { basicReducer, eventReducer } from './user'
import { HYDRATE } from 'next-redux-wrapper'

const initialState = {
  organisation: {},
  isFulfilled: false,
  isRejected: false,
  error: null,
  showPast: false,
  billing: {
    isFulfilled: false,
    isRejected: false,
    info: {
      firstName: '',
      lastName: '',
      companyName: '',
      kvkNumber: '',
      vatId: '',
      address: '',
      zipCode: '',
      city: '',
      country: '',
      emailAddress: '',
      phoneNumber: ''
    }
  },
  events: {
    events: [],
    isFulfilled: false,
    isRejected: false,
    error: null
  },
  pastEvents: {
    events: [],
    isFulfilled: false,
    isRejected: false,
    error: null
  },
  members: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    members: []
  },
  connectedApps: {
    isFulfilled: false,
    isRejected: false,
    pending: false,
    error: null,
    authorising: false,
    connectedApps: [{
      type: 'MOLLIE',
      status: 'NONE'
    }]
  }
}

export default typeToReducer(
  {
    [HYDRATE]: (state, action) => (action.payload.organisation.organisation.id ? {
      ...state,
      isFulfilled: true,
      organisation: action.payload.organisation.organisation,
      events: action.payload.organisation.events
    } : state),
    [SET_SHOW_PAST]: (state, action) => ({
      ...state,
      showPast: action.payload
    }),
    [FETCH_ORGANISATION]: {
      PENDING: (state, action) => ({
        ...state,
        isFulfilled: false,
        isRejected: false,
        error: null
      }),
      FULFILLED: (state, action) => ({
        ...state,
        isFulfilled: true,
        isRejected: false,
        organisation: action.payload
      }),
      REJECTED: (state, action) => ({
        ...state,
        isRejected: true,
        error: action.payload
      })
    },
    [FETCH_BILLING_INFO]: {
      PENDING: (state, action) => ({
        ...state,
        billing: {
          ...state.billing,
          isFulfilled: false,
          isRejected: false,
          error: null,
        }
      }),
      FULFILLED: (state, action) => ({
        ...state,
        billing: {
          ...state.billing,
          isFulfilled: true,
          isRejected: false,
          info: action.payload
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        billing: {
          ...state.billing,
          isRejected: true,
          error: action.payload
        }
      })
    },
    [SET_BILLING_INFO_FIELD]: (state, action) => ({
      ...state,
      billing: {
        ...state.billing,
        info: {
          ...state.billing.info,
          [action.payload.field]: action.payload.value
        }
      }
    }),
    [FETCH_EVENTS]: eventReducer('events'),
    [FETCH_CONNECTED_APPS]: basicReducer('connectedApps'),
    [AUTHORISE_MOLLIE]: basicReducer('connectedApps'),
    [FETCH_MEMBERS]: {
      PENDING: (state, action) => ({
        ...state,
        members: {
          ...state.members,
          isFulfilled: false,
          isRejected: false,
          pending: true,
          error: null
        }
      }),
      FULFILLED: (state, action) => ({
        ...state,
        members: {
          ...state.members,
          isFulfilled: true,
          pending: false,
          members: [
            ...state.members.members,
            ...action.payload
          ]
        }
      }),
      REJECTED: (state, action) => ({
        ...state,
        members: {
          ...state.members,
          isRejected: true,
          pending: false,
          error: action.payload
        }
      })
    },
    // [FETCH_MEMBERS]: {
    //   PENDING: (state, action) => ({
    //     ...state
    //   }),
    //   FULFILLED: (state, action) => ({
    //     ...state,
    //     members: {
    //       ...state.members,
    //       isFulfilled: true,
    //       pending: false,
    //       members: {
    //         ...state.members.members,
    //         ...action.payload
    //       }
    //     }
    //   }),
    //   REJECTED: (state, action) => ({
    //     ...state
    //
    //   })
    // },
    [CHANGE_MEMBER]: {
      PENDING: (state, action) => ({
        ...state
      }),
      FULFILLED: (state, action) => ({
        ...state,
        members: {
          ...state.members,
          members: map(state.members.members, member => member.id === action.payload.id ? {
            ...member,
            ...action.payload
          } : member)
        }
      }),
      REJECTED: (state, action) => ({
        ...state
      })
    },
    [LEAVE_ORGANISATION]: {
      PENDING: (state, action) => ({
        ...state
      }),
      FULFILLED: (state, action) => ({
        ...state,
        organisation: {
          ...state.organisation,
          organisation: {
            ...state.organisation.organisation,
            manager: false
          }
        }
      }),
      REJECTED: (state, action) => ({
        ...state
      })
    },
    [DELETE_MEMBER]: (state, action) => ({
      ...state,
      members: {
        ...state.members,
        members: filter(state.members.members, member => member.id !== action.payload)
      }
    }),
    [FETCH_PAST_EVENTS]: eventReducer('pastEvents'),
    [RESET_ORG_EVENTS]: (state, action) => ({
      ...state,
      members: {
        isFulfilled: false,
        isRejected: false,
        pending: false,
        error: null,
        members: []
      }
    })
  },
  initialState
)
